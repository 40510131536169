
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100;
    src: url("../assets/fonts/montserrat/Montserrat-Thin.ttf") format("truetype");
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    src: url("../assets/fonts/montserrat/Montserrat-ExtraLight.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url("../assets/fonts/montserrat/Montserrat-Light.ttf") format("truetype");
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url("../assets/fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url("../assets/fonts/montserrat/Montserrat-Medium.ttf") format("truetype");
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url("../assets/fonts/montserrat/Montserrat-SemiBold.ttf") format("truetype");
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url("../assets/fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url("../assets/fonts/montserrat/Montserrat-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    src: url("../assets/fonts/montserrat/Montserrat-Black.ttf") format("truetype");
}
