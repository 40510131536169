// region: PLACEHOLDER, IMPORTANT: don't remove

@import "bootstrap/dist/css/bootstrap.min.css";
@import "famfamfam-flags/dist/sprite/famfamfam-flags.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.carousel.min.css";
@import "ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.theme.default.min.css";

@import '@delon/theme/system/index';
@import '@delon/abc/index';
@import '@delon/theme/layout-default/style/index';
@import '@delon/theme/layout-blank/style/index';

@import '@syncfusion/ej2-base/styles/material.css';
@import '@syncfusion/ej2-icons/styles/material.css';
@import '@syncfusion/ej2-buttons/styles/material.css';
@import '@syncfusion/ej2-splitbuttons/styles/material.css';
@import '@syncfusion/ej2-inputs/styles/material.css';
@import '@syncfusion/ej2-lists/styles/material.css';
@import '@syncfusion/ej2-navigations/styles/material.css';
@import '@syncfusion/ej2-popups/styles/material.css';
@import '@syncfusion/ej2-richtexteditor/styles/material.css';
@import "@syncfusion/ej2-layouts/styles/material.css";
@import "@syncfusion/ej2-grids/styles/material.css";
@import "@syncfusion/ej2-filemanager/styles/material.css";

@import './assets/font-awesome/css/all.min.css';

@import './styles/font.less';
@import './styles/theme.less';
@import './styles/index.less';
@import './styles/responsive.less';
