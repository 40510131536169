body {
    background-color: #f8f8f8;
}

body,
.ant-table,
.ant-card {
    font-feature-settings: inherit !important;
    font-variant: inherit !important;
}

.bg-primary-color {
    background-color: @primary-color;
}

.fullscreen {
    min-height: calc(100vh - 60px) !important;
}

.ant-slider {
    height: unset !important;
    margin: 0 !important;
    padding: 0 !important;
}

.kt-home_menu {
    width: 100%;
    left: 0;
    right: 0;
    background: url("../assets/images/background-1.png") rgba(134, 134, 134, .05);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    background-blend-mode: multiply;
}

@customLightPrimary: #227ff41f;


.btn-outline-primary {
    color: @primary-color;
    border-color: @primary-color;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: @primary-color;
    border-color: @primary-color;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: @primary-color;
    border-color: @primary-color;
}

//reuse-tab
.alain-default__fixed .reuse-tab {
    display: none;
    bottom: 0;
    top: auto;
    left: auto;
    padding: 0;
}

.reuse-tab__line .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
}

@media screen and (max-width: 767px) {

    .alain-default__fixed .reuse-tab {
        position: fixed;
    }
}

ul.sidebar-nav li a {
    border-top: 1px solid #555;
}

ul.sidebar-nav ul.sidebar-nav__sub li a {
    border-top: none;
}

.alain-default__collapsed .rps-createOrEdit {
    width: 100% !important;
    margin-left: 0 !important;
}

.rps-createOrEdit {
    width: calc(100% - 170px) !important;
    margin-left: 170px !important;
}

nz-table {
    width: 100% !important
}

.custom-body {
    padding: 16px 0;
    background-color: rgb(255, 255, 255) !important;
    border-radius: 5px !important;
}

.body-frame {
    padding: 10px 30px;
}

@media only screen and (max-width: 767.9px) {
    .body-frame {
        padding: 10px 16px !important;
    }
}

.card-frame {
    border: none;
    box-shadow: 0 4px 24px 0 #22292f24;
    padding: 0 16px;
    background-color: @white;
    border-radius: 4px;
}

.nz-tabset-custom {
    background-color: @white;
    margin-bottom: 20px;
    padding: 10px 0;
    border-radius: 5px;

    .ant-tabs-tab {
        padding: 10px 20px;

        .count {
            color: @red-color;
        }
    }

    .ant-tabs-tab-active {
        background-color: @primary-color;
        border-radius: 8px;

        .ant-tabs-tab-btn {
            color: @white;
        }

        .count {
            color: @white;
        }
    }

    .ant-tabs-ink-bar {
        display: none;
    }
}

.page-header {
    background: unset;
}

.ant-tabs-top .ant-tabs-nav {
    margin: 0;
}

.ant-tabs-top .ant-tabs-nav::before {
    content: unset !important;
}

.ant-form-item-label label {
    font-weight: 500;
}

.search {
    &__form {
        &.ant-form-vertical {
            .ant-form-item {
                margin-bottom: 16px;
                margin-right: 0;
                display: block;
            }

            .ant-form-item-label,
            .ant-form-item-control-wrapper {
                padding: 0 !important;
            }
        }

        margin-bottom: 0px !important;
    }
}

.text-disabled-color {
    color: rgba(0, 0, 0, 0.25);
}

.ant-alert {
    padding-right: 30px !important;
}

.modal-include-tabs .ant-alert {
    margin-bottom: 16px !important;
}

.ant-form-item-children-icon {
    margin-right: 5px;
}


.alain-default__content nz-input-group {
    width: 100%;
}


.margin-left-15 {
    margin-left: 15px !important;
}

.margin-left-5 {
    margin-left: 5px !important;
}

.margin-right-5 {
    margin-right: 5px !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-top-25 {
    margin-top: 25px !important;
}

.margin-top-30 {
    margin-top: 30px !important;
}

.margin-top-35 {
    margin-top: 35px !important;
}

.margin-top-40 {
    margin-top: 40px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.margin-bottom-20 {
    margin-bottom: 20px !important;
}

.margin-bottom-25 {
    margin-bottom: 25px !important;
}

.margin-bottom-30 {
    margin-bottom: 30px !important;
}

.margin-bottom-35 {
    margin-bottom: 35px !important;
}

.margin-bottom-40 {
    margin-bottom: 40px !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-bold {
    font-weight: bold;
}

//fiz nz Icon
.anticon svg {
    display: block;
}

//btn Custom
.btn-outline-success {
    color: #0CBA70;
    border-color: #0CBA70;
}

.no-select-text {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.page-header__title,
.page-header__action {
    margin-bottom: 5px;
}

.pageTitle {
    font-weight: 600;
    font-size: 18px;
    color: @text-color;
}

.ora-form-case {
    margin: 10px 0px 10px 0px !important;
    text-align: center;
}

.btn-form-case {
    margin: 0px 5px 0px 5px !important
}

.padding-0 {
    padding: 0;
    background: white !important;
}

.radion-custom {
    padding: 1em;
}

label {
    margin-bottom: 0px;
}

//ant-table
.ant-table-thead > tr > th {
    color: #000;
    background: #e5eaee;
}

//ant-card
.ora-custom {
    margin-bottom: 8px !important;
    border: none !important;

    .ant-card-body {
        padding: 0px !important;
    }
}

.ora-custom-card {
    margin-bottom: 0px !important;

    .ant-card-body {
        padding: 0 !important;
    }
}

.green {
    color: green !important;
}

.red {
    color: red !important;
}

.right {
    right: 0 !important;
    float: right !important;
}

.ml-10 {
    margin-left: 10px;
}

.mr-10 {
    margin-left: 100px !important;
}

.qr-img {
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 15vh;
}

.mb-215 {
    margin-top: -215px;
}


.ora-label-custom {
    padding-left: 15px !important;
    padding-right: 6px !important;
    padding-top: 3.5px !important;
    padding-bottom: 3.5px !important;
    border: 1px solid #d3d3d3 !important;
    font-weight: 500;
    cursor: default;
}

.ora-label-custom .icon-c {
    padding-right: 6px !important;
}

.inp-nameUsing {
    display: flex;
    width: 100% !important;
}

.inp-nameUsing input#name {
    width: 90% !important;
}

.inp-nameUsing .icon-form {
    color: #089759;
    font-size: 20px;
    margin-left: 5px !important;
    width: 10% !important;
}

.editor-syncfusion {
    min-height: 250px;

    .e-toolbar-wrapper {
        height: auto !important;
    }

    .e-rte-content {
        min-height: 250px;
    }
}

@media screen and (max-width: 1028px) {
    .qr-img {
        margin-top: 20px;
        margin-left: 20px;
    }
}

@media screen and (max-width: 768px) {

    .editor-syncfusion {
        min-height: auto;

        .e-rte-content {
            min-height: auto;
        }
    }

    .qr-img {
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-left: 20px;
    }

    .rps-createOrEdit {
        width: 100% !important;
        margin-left: 0 !important;
    }

    .inp-nameUsing {
        display: flex;
        width: 100% !important;
    }

    .inp-nameUsing input#name {
        width: 85% !important;
    }

    .inp-nameUsing .icon-form {
        color: #089759;
        font-size: 18px;
        margin-left: 5px !important;
        width: 10% !important;
    }
}

@media screen and (max-width: 578px) {
    .mar-left {
        margin-left: 0 !important;
    }

    .ml-10 {
        display: none !important;
    }

    .inp-nameUsing {
        display: flex;
        width: 100% !important;
    }

    .inp-nameUsing input#name {
        width: 85% !important;
    }

    .inp-nameUsing .icon-form {
        color: #089759;
        font-size: 15px;
        margin-left: 5px !important;
        width: 10% !important;
    }
}

@media screen and (max-width: 380px) {
    .ml-140 {
        margin-left: 25px !important;
    }

    .qr-img {
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-left: 0;
    }
}

.hidden {
    display: none !important;
}


//ant-notification
.ant-notification-notice-message {
    color: @text-color;
}

//ant-input
.ant-input[disabled] {
    background-color: #fff;
    cursor: not-allowed;
    opacity: 1;
    color: #000;
    pointer-events: none;
    border: none;
}

.ant-input[readonly] {
    border: none;
}

.ant-input:focus,
.ant-input-focused {
    box-shadow: none;
}

//ant-select
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #000;
    background: #fff;
    cursor: not-allowed;
    border: none;
}

.ant-select-disabled .ant-select-arrow {
    cursor: not-allowed;
    display: none;
}

//ant-picker
.ant-picker.ant-picker-disabled {
    background: #fff;
    border: none;
    color: #000;
}

.main-ora-date .ant-picker.ant-picker-disabled {
    border: none;
}

.ora-input-date-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
}

.oda-select-dropdown hr {
    margin: 5px;
}

.ant-form-item-explain,
.ant-form-item-extra {
    margin-top: 0px !important;
}

//modal

.modal-header {
    background: #035f96;
}

.card-custom {
    .ant-card-body {
        padding: 15px;
    }
}

.ant-select-selection-item {
    font-weight: 500;
}

.btn-custom-ora {
    span {
        font-weight: 600;
    }
}

.card-custom {
    // box-shadow: 4px 2px 15px 5px #22292f1a;
    box-shadow: @box-shadow-color;

    .ant-card-head {
        min-height: 20px;
        padding: 0 20px;
        border-bottom: none;

        .ant-card-head-title {
            padding: 10px 0 0px;
            font-weight: 600;
        }
    }
}


.ora-table-custom {
    th {
        background-color: @customLightPrimary !important;
        font-weight: 600 !important;
        color: rgb(24, 31, 40) !important
    }

    .list-icon-status {
        border-radius: 25px;
        width: 20px;
        height: 20px;
        padding: 0px;
        margin-left: 6px;

        i {
            padding: 3px;
        }
    }

    td {
        .ant-tag {
            border-radius: 25px;
        }
    }
}

.ora-combo {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    nz-select {
        width: 100%;
    }
}

.ora-close {
    cursor: pointer;
}

.ora-content {
    .header-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #181f28;
        padding-top: 25px;
    }
}

.matching-content {
    .matching-item {
        background-color: #f4f4f4;
        border-radius: 25px;
        padding: 5px;
        width: 86%;
        cursor: pointer;
        font-weight: 600;
    }

    .matching-item-default {
        background-color: #f1c84d;

        .userAnswerContent {
            color: white;
        }
    }
}

.matching-question-custom {
    .left-item {
        display: flex;
        overflow-y: auto;
        height: 60px;
        padding: 10px;
        border: 1px solid @root-primary;
        margin-bottom: 20px;
        background: rgba(34, 127, 244, .12);
        text-align: start;
        border-right: 0;
        border-radius: 6px 0 0 6px;

        .bd-top {
            position: absolute;
            width: 1px;
            top: 0;
            height: 19px;
            right: 0;
            border-right: 1px solid @root-primary;
        }

        .bd-bottom {
            position: absolute;
            width: 1px;
            bottom: 0;
            height: 19px;
            right: 0;
            border-right: 1px solid @root-primary;
        }
    }

    .left-item:after {
        top: 50%;
        content: "";
        position: absolute;
        pointer-events: none;
        width: 16px;
        height: 25px;
        transform: translateY(-50%);
        background: rgba(34, 127, 244, .12);
        border: 1px solid @root-primary;
        left: 100%;
        border-left: 0;
        border-radius: 0 50px 50px 0;
    }

    .right-item {
        display: flex;
        overflow-y: auto;
        height: 60px;
        padding: 10px;
        border: 1px solid @root-primary;
        margin-bottom: 20px;
        // background: rgba(34, 127, 244, .12);
        text-align: end;
        border-left: 0;
        padding-left: 20px;
        border-radius: 0 6px 6px 0;

        .bd-top {
            position: absolute;
            width: 1px;
            top: 0;
            height: 19px;
            left: 0;
            border-left: 1px solid @root-primary;
        }

        .bd-bottom {
            position: absolute;
            width: 1px;
            bottom: 0;
            height: 19px;
            left: 0;
            border-left: 1px solid @root-primary;
        }
    }

    .right-item:before {
        top: 50%;
        content: "";
        position: absolute;
        pointer-events: none;
        width: 16px;
        height: 25px;
        background: #fff;
        transform: translateY(-50%);
        border: 1px solid @root-primary;
        left: 0;
        border-left: 0;
        border-radius: 0 50px 50px 0;
    }

    .right-item.matched:before {
        left: 0;
        border-radius: 0 50px 50px 0;
        border: 0;
    }

    .right-item.matched .bd-bottom,
    .right-item.matched .bd-top {
        display: none;
    }

    .cdk-drag-preview {
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
        background: rgba(34, 127, 244, .12);
    }
    /* Animate items as they're being sorted. */
    .cdk-drop-list-dragging {
        .cdk-drag {
            transition: transform 100ms cubic-bezier(0, 0, 0.2, 1);
        }
    }
    /* Animate an item that has been dropped. */
    .cdk-drag-animating {
        transition: transform 100ms cubic-bezier(0, 0, 0.2, 1);
    }


    .matching-item-select.isCorrectAnswer {
        border: 1px solid #b7eb8f;
        color: #389e0d;
        background: #f6ffed;

        .userAnswerContent {
            color: #389e0d;
        }

        .bd-top {
            border-right: 1px solid #b7eb8f;
        }

        .bd-bottom {
            border-right: 1px solid #b7eb8f;
        }
    }

    .matching-item-select.isWrongAnswer {
        color: #cf1322;
        background: #fff1f0;
        border: 1px solid #ffa39e;

        .userAnswerContent {
            color: #cf1322;
        }

        .bd-top {
            border-right: 1px solid #ffa39e;
        }

        .bd-bottom {
            border-right: 1px solid #ffa39e;
        }
    }

    .matching-item-select.isCorrectAnswer:after {
        border: 1px solid #b7eb8f;
        color: #389e0d;
        background: #f6ffed;
    }

    .matching-item-select.isWrongAnswer:after {
        color: #cf1322;
        background: #fff1f0;
        border: 1px solid #ffa39e;
    }

    .left-item.matching-item-select.isCorrectAnswer, .left-item.matching-item-select.isWrongAnswer {
        border-right: 0;
    }

    .right-item.matching-item-select.isCorrectAnswer, .right-item.matching-item-select.isWrongAnswer {
        border-left: 0;
    }

    .left-item.matching-item-select.isCorrectAnswer:after, .left-item.matching-item-select.isWrongAnswer:after {
        border-left: 0;
    }
}

.list-user-body {
    .ant-collapse-item:has(.student-active) {

        .ant-collapse-header {
            background-color: #e4effe !important;
        }
    }
}

.ora-timeline-custom {

    .number-content {
        border: 2px solid #227ff4;
        padding: 21px 0px;
        border-radius: 50px;
        background: #fff;
        width: 60px;
        height: 60px;
    }

    .ant-timeline-item {
        min-height: 100px !important;
    }
}

.list-exam {
    .owl-stage {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-box;
        display: box;
    }

    .owl-item {
        display: inline !important;
    }
}

.ora-custom {
    .ant-table-cell-fix-left, .ant-table-cell-fix-right {
        z-index: 1 !important;
    }
}

.ora-tag {
    background-color: rgba(91, 156, 241, 0.2);
    padding: 3px 5px;
    border-radius: 20px;
    font-size: 10px;
    color: #0b79ff;
}
