

.page-header__detail {
    .page-header__main {
        .page-header__row {
            .page-header__title {
                display: none;
            }
        }
    }
}

@media (max-width: 768px) {
    .page-header__action, .page-header__extra {
        text-align: right;
    }

    .default__nav-wrap {
        .default__nav {
            .title-he-thong {
                font-size: 13px;
                color: @primary-color;
                margin: 0px;
                font-weight: bold;
            }
        }
    }

    .ant-modal {
        width: 100% !important;
    }

    .alain-fullscreen {
        position: relative;
        top: -10px;
    }
}

@media (min-width: 768px) {
    .default__nav-wrap {
        .default__nav {
            .title-he-thong {
                font-size: 20px;
                color: @primary-color;
                margin: 0px 0px 0px 20px;
                font-weight: bold;
            }
        }
    }

    .default__content {
       // margin: 95px 5px 0px @width-nav !important;
        padding:0px 5px 0px 70px;

        .footer-fixed-page {
            position: fixed;
            bottom: 0;
            left: 200px;
            width: calc(100% - 200px);
            z-index: 1;
        }
    }

    .alain-default__collapsed .alain-default__content {
        // margin: 95px 5px 0px 70px !important;


        .footer-fixed-page {
            left: 64px;
            width: calc(100% - 64px);
        }
    }

    .alain-default__fixed .reuse-tab + router-outlet {
         height: 0px !important; 
    }

    .alain-fullscreen {
        position: relative;
        top: -40px;
    }
}

@media (max-width: 767.9px) {
    .default__content {
        margin:unset !important;
        padding:unset !important;
        
    }
}